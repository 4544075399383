import styled from '@emotion/styled'
import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import Bg from '../../images/map-bg.webp'
import { PopupText } from 'react-calendly'

const WhoSection = styled.section`
  padding: 75px 20px 0;
  text-align: left;
  background-color: var(--color-black);
  background: url( ${Bg} ) no-repeat;
  background-size: cover;
  @media (max-width: 768px) {
    padding: 75px 20px 0;
  }
`
const Content = styled.div`
  max-width: 1110px;
  position: relative;
  margin: 0 auto;
  padding-bottom: 80px;
`
const H2 = styled.h2`
  font-size: 50px;
  font-weight: bold;
  line-height: 63px;
  max-width: 716px;
  margin-bottom: 60px;
  font-family: var(--font-heading);
  color: var(--color-green-dark);
  @media (max-width: 780px) {
    font-size: 35px;
    line-height: 1.2;
    font-weight: 500;
  }
`
const Ul = styled.ul`
  font-family: var(--font-body);
  list-style: none;
  color: #fff;
  font-size: 36px;
  line-height: 48px;
  margin-top: 82px;
  margin-left: 0;
  position: relative;
  @media (max-width: 768px) {
    font-size: 32px;
    line-height: 1.4;
  }
  @media (max-width: 468px) {
    font-size: 26px;
  }
  li {
    margin-bottom: 296px;
    @media (max-width: 768px) {
      margin-bottom: 200px;
    }
  }
  li:nth-of-type(1) {
    padding-left: 105px;
    @media (max-width: 768px) {
      padding-left: 120px;
    }
  }
  li:nth-of-type(2) {
    padding-left: 235px;
    @media (max-width: 468px) {
      padding-left: 100px;
      margin-top: 102px;
      margin-bottom: 84px;
    }
  }
  li:nth-of-type(3) {
    padding-left: 70px;
    max-width: 727px;
    margin-bottom: 192px;
     @media (max-width: 768px) {
        padding-left: 110px

     }
     @media (max-width: 468px) {
       margin-bottom: 125px;
     }
  }
  li:nth-of-type(4) {
    margin-left: 0px;
    @media (max-width: 468px) {
       margin-left: 20px;
       margin-bottom: 240px;
     }
  }
  li:nth-of-type(5) {
    margin-left: 300px;
    margin-bottom: 400px;
    @media (max-width: 768px) {
      margin-left: 280px;
      margin-top: 30px;
      margin-bottom: 300px;
    }
    @media (max-width: 468px) {
       margin-top: 70px;
       margin-left: 30px;
     }
  }

`

const MapImg = styled.div`
  position: absolute;
  left: -138px;
  @media (max-width: 768px) {
    transform: scale(.75);
    top: -84px;
    left: -98px;
  }
`
const Button = styled.div`
  a {
    width: 197px;
    padding: 15px 0 18px;
    background-color: var(--color-orange);
    margin: 45px 0 0 300px;
    display: block;
    border-radius: 45px;
    color: var(--color-black);
    font-family: Stolzl;
    text-decoration: none;
    font-family: var(--font-body);
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    @media (max-width: 768px) {
      margin-left: 100px;
    }
    &:hover {
      background-color: var(--color-green-dark);
    }
  }
`


function Who () {

  const data = useStaticQuery(graphql`
    query {
      map: file(relativePath: { eq: "treasure-map.png" }) {
        childImageSharp {
          fixed(width: 415, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `)
  return (
    
    <WhoSection>

      <Content>
        <H2>We Believe Social Media Marketing is A Lot Like Treasure Hunting…</H2>
        
        <MapImg>
          <Img alt="" role="presentation" fadeIn={false} fixed={data.map.childImageSharp.fixed} />
        </MapImg>
        <Ul>
          <li>Create and Work off Great Maps</li>
          <li>Do Not Fear the Unknown</li>
          <li>Uncover the Clues and Connect the Dots</li>
          <li>Fortune Favours the Bold</li>
          <li>Have a Sense of Adventure and Enjoy the Journey</li>
        </Ul>
        <Button href="#">            
          <PopupText
            text="Meet Our Team"
            url="https://calendly.com/streamteamca/30min"
          />
        </Button>
      </Content>
    </WhoSection>
  )
}

export default Who
