import React from "react"
import Layout from "../components/layout"

import Hero from "../components/homepage/hero"
import Map from "../components/homepage/map"
import Footer from "../components/footer"

export default () => (
  <Layout>
    <Hero/>
    <Map/>
    <Footer/>
  </Layout>
)
