import styled from '@emotion/styled'
import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faEye, faEyeSlash} from "@fortawesome/free-solid-svg-icons"
import { PopupText } from 'react-calendly'

const HeroSection = styled.section`
  background-color: var(--color-orange);
  height: 100vh;
  min-height: 718px;
  padding: 0 20px 20px;
  position: relative;
  text-align: center;
  .spotlight {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 3;
    background-image: radial-gradient(
        circle,
        transparent 0px,
        rgba(30, 31, 38, 1) 0px
    );
    @media (max-width: 767px) {
      display: none;
    }
  }
  @media (max-width: 768px) {
    padding-bottom: 75px;
    overflow: hidden;
    height: auto;
    background-color: var(--color-black);
  }
  &.open {
    > div:first-of-type {
      display: none;
    }
    h1:first-of-type {
      color: var(--color-black);
    }
    p:first-of-type {
      color: var(--color-black);
    }
    a:first-of-type {
      background: var(--color-black);
      color: #fff;
    }
  }
`
const List = styled.ul`
  font-size: 30px;
  font-weight: 500;
  line-height: 40px;
  letter-spacing: 0.33px;
  text-align: center;
  color: #ffffff;
  position: absolute;
  z-index: 2;
  font-family: var(--font-heading);
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  width: 100%;
  justify-content: space-between;
  top: 0;
  left: 0;
  list-style: none;
  margin-left: 0;
  > li {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 33%;
    height: 50%;
    text-align: center;
  }
  span {
    max-width: 250px;
  }
  @media (min-width: 767px) {
    > li:nth-child(5) {
      margin-top: 70px;
    }
    > li:nth-child(2) {
      margin-top: -50px;
    }
  }
  @media (max-width: 767px) {
    position: relative;
    color: var(--color-orange);
    height: auto;
    width: 100%;
    display: block;
    z-index: 4;
    text-align: center;
    line-height: 1.3;
    font-size: 20px;
    margin-top: 35px;
    li {
      width: 100%;
      height: auto;
      display: block;
    }
  }
`
const H1 = styled.h1`
  font-family: var(--font-heading);
  font-size: 66px;
  font-weight: 600;
  text-align: center;
  color: var(--color-green-dark);
  @media (max-width: 1026px) {
    font-size: 78px;
  }
  @media (max-width: 760px) {
    font-size: 48px;
  }
`
const P = styled.p`
  color: #ffffff;
  font-family: var(--font-body);
  margin: 25px auto 45px;
  max-width: 556px;
  font-size: 24px;
  line-height: 26px;
  letter-spacing: 0.5px;
`
const ContentContainer = styled.div`
  max-width: 1288px;
  padding: 265px 0 20px;
  margin: 0 auto;
  position: relative;
  text-align: center;
  z-index: 4;
  @media (max-width: 767px) {
    padding-top: 200px;
    padding-bottom: 80px;
  }
`
const Button = styled.div`
  a {
    text-decoration: none;
    width: 197px;
    padding: 15px 0 18px;
    background-color: var(--color-green-light);
    margin: 45px auto 0;
    display: block;
    border-radius: 45px;
    color: var(--color-black);
    font-family: Stolzl;
    font-family: var(--font-body);
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    &:hover {
      background-color: var(--color-green-dark);
    }
  }
`
const EyeButton = styled.a`
  padding: 10px;
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 3;
  background-color: var( --color-green-dark);
  display: block;
  color: var(--color-black);
  font-family: Stolzl;
  text-decoration: none;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  &:hover {
    background-color: var(--color-green-light);
  }
  @media (max-width: 767px) {
    display: none;
  }
`

class Hero extends React.Component {

  componentDidMount() {
    // When the component is mounted, add your DOM listener.
    document.addEventListener('mousemove', this.updateSpotlight);
  }

  updateSpotlight = (e) => {
    const spotlight = document.getElementsByClassName('spotlight')[0];
    let spotlightSize = 'transparent 105px, rgba(30, 31, 38, 1) 125px)';
    spotlight.style.backgroundImage = `radial-gradient(circle at ${e.pageX / window.innerWidth * 100}% ${e.pageY / window.innerHeight * 100}%, ${spotlightSize}`;
  }

  constructor(props) {
    super(props)
    this.state = {
      isActive: false
    }
    this.toggleActive = this.toggleActive.bind(this)
  }

  toggleActive() {
    this.setState({
      isActive: !this.state.isActive
    })
  }

  render() {

    const { isActive } = this.state

    return (
      <HeroSection className={ this.state.isActive ? "open" : "closed" } >
        <div className="spotlight"></div>
        <ContentContainer>
          <H1>The Secret to Your Success</H1>
          <P>For business owners by business owners. Our team of sales, marketing, and communications experts help you design and create unparalleled social media experiences for your customers.</P>
          <Button>
            <PopupText
              text="Take the First Step"
              url="https://calendly.com/streamteamca/30min"
            />
          </Button>
        </ContentContainer>
        <List>
          <li><span>Targeted Marketing Strategies</span></li>
          <li><span>Market Research</span></li>
          <li><span>Executive Ghost Writing</span></li>
          <li><span>Campaign Designs</span></li>
          <li><span>Custom Content Creation</span></li>
          <li><span>Improved website Accessibility</span></li>
        </List>
        <EyeButton onClick = {this.toggleActive}>
          <FontAwesomeIcon icon={faEye} />
        </EyeButton>
      </HeroSection>
    );
  }
}

export default Hero
