import React from "react"
import styled from '@emotion/styled'
import { PopupText } from 'react-calendly'

const Footer = styled.footer`
  padding-bottom: 40px;
  padding-top: 40px;
  position: relative;
  z-index: 2;
  background: var(--color-green-dark);
  text-align: center;
  a {
    color: color: var(--color-black);
    font-family: var(--font-body);
    font-size: 24px;
    font-weight: 500;
    line-height: 1;
    letter-spacing: 0.72px;
    text-align: center;
    text-decoration: none;
    display: block;
    margin-right: 24px;
    padding-right: 24px;
    position: relative;
    &:hover {
      color: var(--color-black);
    }
    @media (max-width: 767px) {
      padding-bottom: 16px;
      margin-left: 0;
    }
    &:after {
      content: '';
      height: 6px;
      width: 6px;
      border-radius: 50%;
      background: var(--color-black);
      font-size: 48px;
      right: 0;
      bottom: 8px;
      position: absolute;
    }
  }
  span {
    padding-right: 0;
    border-right: 0;
  }
`

const Copyright = styled.span`
  color: var(--color-black);
  font-family: var(--font-body);
  font-size: 24px;
  line-height: 30px;
  letter-spacing: 0.72px;
  text-align: center;
  display: block;
  span {
    color: #fff;
  }
`

const Flex = styled.div`
  @media (min-width: 767px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

export default () => (
  <Footer>
    <Flex>
      <PopupText
        text="Book a Meeting"
        url="https://calendly.com/streamteamca/30min"
      />
      <a href="mailto:streamteamca@gmail.com">streamteamca@gmail.com</a>       
      <Copyright> &copy; Copyright 2020, <span>Stream Team</span></Copyright>
    </Flex>
  </Footer>
)
